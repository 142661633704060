import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Inicio from './navegacion/Inicio.js';
import Business from './navegacion/Business.js';
import Jobs from './navegacion/Jobs.js';
import './css/Site.css'
import './css/StyleADE.css'
import './css/StyleCD.css'

function App() {
  return (
    <Router>
      <div className="blur-op"></div>
      <Routes>
        <Route path='/' index element={<Inicio />} />
        <Route path='/business' element={<Business />} />
        <Route path='/jobs/:refe/:ubica' element={<Jobs />} />
        <Route path='/jobs/:refe' element={<Jobs />} />
        <Route path='/jobs' element={<Jobs />} />
      </Routes>
    </Router>
  );
}

export default App;
