import React, { Component } from 'react';
import Piepagina from './Piepagina.js';
import Navbar from './Navbar.js';
import '../js/QueryHS.js'
import { useParams } from 'react-router-dom';

export default function Jobs() {
    const { refe, ubica } = useParams();
    return (
        <>
            <Jobs2 refe={refe} ubica={ubica} />
        </>
    );
}

class Jobs2 extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            data: [],
            referencia: (props.refe == undefined ? "" : props.refe),
            SetReferencia: (props.refe == undefined ? "" : props.refe),
            ubicacion: (props.ubica == undefined ? "" : props.ubica),
            SetUbicacion: (props.ubica == undefined ? "" : props.ubica),
        });
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }
    async componentDidMount() {
        const $ = window.$;
        $(".navbar").stop(true).animate({ "background": "#000000a3" });
        $(".logo_img img").stop(true).animate({ width: 140 });
        $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "65px" });
        $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "20px" });
        $(".logo_img").stop(true).animate({ "padding": "23px 0px" });
        $(".nav-empty").stop(true).animate({ "padding": "23px 0px", "margin-top": "0px" }, 100);
        await this.fetchOfertaEmpleo();
    }
    fetchOfertaEmpleo = async () => {
        let response = await fetch("https://api.hameservices.com/trabajo");
        let data = await response.json();
        this.setState({
            data: JSON.parse(data.body)
        })
    }
    handleSubmit(event) {
        this.setState({ referencia: this.state.SetReferencia })
        this.setState({ ubicacion: this.state.SetUbicacion })
        event.preventDefault();
      }
    render() {
        return (
            <div>
                <Navbar showHideNav='hide' urlactivo='jobs' />
                <main className="content">
                    <div className="wrapper">
                        <section id="Home" className="over_wrapper">
                            <div className="fw_img-extra">
                                <div className="fw_extralight">
                                    <div className="flag-back"></div>
                                    <div className="drop-mn">
                                        <h2>
                                            Ofertas Laborales
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="over_wrapper">
                            <div className="MinionActive"></div>
                            <div className="MinionActive"></div>
                            <div className="fw_extralight fw_bs">
                                <h1>¿Estás buscando la mejor oferta de trabajo?</h1>
                            </div>
                            <div className="container-hm">
                                <div className="vd-globe"></div>
                                <div className="vd-globe-line"></div>
                                <div className="custom-container box_general">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="box-content">
                                            <input type="text" name="ref" onChange={(e) => this.setState({ SetReferencia: e.target.value })} value={this.state.SetReferencia} placeholder="Referencia" />
                                            <input type="text" name="ubica" onChange={(e) => this.setState({ SetUbicacion: e.target.value })} value={this.state.SetUbicacion} placeholder="Ubicación" />
                                            <button>BUSCAR</button>
                                        </div>
                                    </form>
                                    <div className="content-model-list">
                                        {this.state.data.map((job) => {
                                            return (
                                                ((job.nombre.toUpperCase()).indexOf(this.state.referencia.toUpperCase()) != -1 && (job.ciudad.toUpperCase()).indexOf(this.state.ubicacion.toUpperCase()) != -1 ?
                                                    <div key={job.sequencer} className="box-data-item">
                                                        <h1>{job.nombre}</h1>
                                                        <div className="content-icon">
                                                            <div className="">
                                                                <i className="fa fa-map-marker" aria-hidden="true"></i><span>{job.ciudad}</span>
                                                                <i className="fa fa-clock-o" aria-hidden="true"></i><span>{job.modalidad}</span>
                                                                <i className="fa fa-money" aria-hidden="true"></i><span>{job.salario}</span>
                                                            </div>
                                                        </div>
                                                        <div className="content-data">{job.descripcion}</div>
                                                    </div>
                                                    : null)
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Piepagina />
                    </div>
                </main>
            </div>
        );
    }
}