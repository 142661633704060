import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageCeluhs from '../images/celuhs.png';
import ImageVideoMascara from '../images/video-mascara.svg';
import Imagelinewg from '../images/linewg.jpg';
import Imagebackground2 from '../images/background2.jpg';
import Imagebackground3 from '../images/background3.jpg';
import Piepagina from './Piepagina.js';
import Navbar from './Navbar.js';
import '../js/QueryHS.js'

export default class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referencia: '',
            ubicacion: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const $ = window.$;
        $(".navbar").stop(true).animate({ "background": "#000000a3" });
        $(".logo_img img").stop(true).animate({ width: 140 });
        $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "65px" });
        $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "20px" });
        $(".logo_img").stop(true).animate({ "padding": "23px 0px" });
        $(".nav-empty").stop(true).animate({ "padding": "23px 0px", "margin-top": "0px" }, 100);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

    }

    render() {
        return (
            <div>
                <Navbar showHideNav='show' urlactivo='inicio' />
                <main className="content">
                    <div className="wrapper">
                        <section id="Home" className="over_wrapper">
                            <div className="fw_img-extra">
                                <div className="fw-curv-extra">
                                    <div className="fw_extralight">
                                        <div className="flag-back"></div>
                                        <div className="drop-mn">
                                            <h2>
                                                ENCUENTRA EL TRABAJO<br />DE TUS SUEÑOS
                                            </h2>
                                            <div className="box-content">
                                                <input type="text" name="referencia" onChange={this.handleInputChange} placeholder="Referencia" />
                                                <input type="text" name="ubicacion" onChange={this.handleInputChange} placeholder="Ubicación" />
                                                <Link className="ebutton" to={"jobs/"+this.state.referencia+"/"+this.state.ubicacion}>BUSCAR</Link>
                                            </div>
                                            <a className="btn-ref scroll-link" href="#About">NUESTROS BENEFICIOS</a>
                                        </div>
                                        <div id="cubes-wrapp">
                                            <div id="elem_five" className="element_cc item_co">
                                                {/* <span style="opacity: 0.5;"></span> */}
                                                <a href="#homefw" className="scroll-link">explorar</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img className="celmania" src={ImageCeluhs} alt="celmania" />
                            </div>
                        </section>
                        <section id="homefw" className="over_wrapper">
                            <div className="MinionActive"></div>
                            <div className="fw_extralight backwhite">
                                <div className="clonefw">
                                    <i className="fa fa-cloud" aria-hidden="true"></i>
                                    <span>Somos socios de negocio de los principales grupos corporativos del país.</span>
                                </div>
                                <div className="clonefw">
                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                    <span>Trabajamos con los principales fabricantes tecnológicos a nivel nacional.</span>
                                </div>
                                <div className="clonefw">
                                    <i className="fa fa-rocket" aria-hidden="true"></i>
                                    <span>Tenemos experiencia con prácticas corporativas en la creación de proyectos.</span>
                                </div>
                            </div>
                            <div className="container-hm">
                                <div className="vd-globe"></div>
                                <div className="vd-globe-line"></div>
                                <div className="custom-container">
                                    <div className="slick-track">
                                        <div className="section-headline">
                                            <h2>Hame Services</h2>
                                            <p>Somos los mejores aliados del Talento Humano, conectamos el potencial de nuestros postulantes con las necesidades de las empresas de manera eficiente. Estamos al servicio de grandes y pequeñas empresas de todos los sectores económicos con nuestra iniciativa de empleo.</p>
                                            <p>Si eres una organización que está buscando al colaborador adecuado que se adapte mejor a tu empresa, HAME SERVICES hará la mejor selección como si fueras tu mismo.</p>
                                        <p>Si eres un talento que está buscando el trabajo de sus sueños que se adapte mejor a tus capacidades y habilidades, HAME SERVICES te asesora y te guía hacía la mejor elección laboral.</p>
                                        </div>
                                    </div>
                                    <div className="video-rev">
                                        <div className="video-pers">
                                            <img alt="imgvideo" src={ImageVideoMascara} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="About" className="over_wrapper">
                            <div className="back-smb">
                                <div className="MinionActive"></div>
                                <div className="Content_Over">
                                    <div className="titleDesc">
                                        BENEFICIOS
                                    </div>
                                </div>
                                <div className="fw_extralight">
                                    <div className="column-3-lgt">
                                        <div>
                                            <h1><i className="fa fa-modx" aria-hidden="true"></i><span>Legalidad</span></h1>
                                            <p>Somos una empresa formal y con experiencia, que te brinda las garantías para seguir adelante y hacer crecer a tu negocio.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-google-wallet" aria-hidden="true"></i><span>Seguridad</span></h1>
                                            <p>Mantenemos la confidencialidad de tu información acorde a nuestra política de privacidad, ya que contamos con procesos definidos para salvaguardar la integridad de tus procesos de selección.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-fonticons" aria-hidden="true"></i><span>Agilidad</span></h1>
                                            <p>Procesos simples y rápidos para ofrecerte las mejores soluciones en el menor tiempo posible.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-medium" aria-hidden="true"></i><span>Servicio</span></h1>
                                            <p>Tenemos el compromiso de brindarte el mejor servicio que esté alineado a los procesos de tu negocio.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-qq" aria-hidden="true"></i><span>Mejorar credito</span></h1>
                                            <p>Cuidamos tu información acorde a nuestra política de privacidad y procesos definidos. Trabajamos con aliados que cumplen con todos los requisitos exigidos por la ley, con ello, se asegura tu tranquilidad y seguridad de la información.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-slideshare" aria-hidden="true"></i><span>Confidencialidad</span></h1>
                                            <p>Procesos simples, para tener respuesta a tu solicitud en 48 horas. No tenemos requisitos complejos para tu solicitud. Nuestros procesos están documentados y soportados tecnológicamente lo que nos permiten hacer mejoramiento continuo de tiempos de aprobación.</p>
                                        </div>
                                        <div>
                                            <h1><i className="fa fa-mixcloud" aria-hidden="true"></i><span>Facilidad</span></h1>
                                            <p>No necesitas tener un aval o codeudor. La solicitud de crédito no tiene costo, es gratuita.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section id="add1" className="over_wrapper">
                            <div className="MinionActive"></div>
                            <img src={Imagelinewg} alt="line" />
                        </section>
                        <section id="servicesq" className="over_wrapper">
                            <div className="fw_extra_combo">
                                <div className="fwsk" hv="pd1">
                                    <Link to='/jobs'>
                                        <div className="MinionActive"></div>
                                        <div className="pd-mini">
                                            <div>
                                                <h2>Impulsa tu carrera</h2>
                                                <p>Estás más cerca de lograr tus metas, el momento es ahora.</p>
                                            </div>
                                        </div>
                                        <div className="pd-link">
                                            <div>Descubre</div>
                                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </Link>
                                </div>
                                <div></div>
                                <div className="fwsk" hv="pd2">
                                    <Link to="/business">
                                        <div className="MinionActive"></div>
                                        <div className="pd-mini">
                                            <div>
                                                <h2>Potencia tu negocio</h2>
                                                <p>Lleva tu compañia a otro nivel con nuestros servicios.</p>
                                            </div>
                                        </div>
                                        <div className="pd-link">
                                            <div>Descubre</div>
                                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </div>
                                    </Link>
                                </div>
                                <img className="b1-bk pd1" src={Imagebackground2} alt="b1" />
                                <img className="b1-bk pd2" src={Imagebackground3} alt="b2" />
                            </div>
                        </section>
                        <Piepagina />
                    </div>
                </main>
            </div>
        );
    }
}