import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Piepagina from './Piepagina.js';
import Navbar from './Navbar.js';
import '../js/QueryHS.js'

export default class Business extends Component {
    state = ({
        dataAreaSector: [],
        dataUbicaciones: []
    });

    componentDidMount() {
        const $ = window.$;
        $(".navbar").stop(true).animate({ "background": "#000000a3" });
        $(".logo_img img").stop(true).animate({ width: 140 });
        $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "65px" });
        $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "20px" });
        $(".logo_img").stop(true).animate({ "padding": "23px 0px" });
        $(".nav-empty").stop(true).animate({ "padding": "23px 0px","margin-top":"0px" }, 100);
        this.fetchData();       
    }
    fetchData = async () => {
        let response = await fetch('https://api.hameservices.com/areasector');
        let dataAreaSector = await response.json();

        let response_1 = await fetch('https://api.hameservices.com/ubicacion');
        let dataUbicaciones = await response_1.json();

        this.setState({
            dataAreaSector: JSON.parse(dataAreaSector.body),
            dataUbicaciones: JSON.parse(dataUbicaciones.body)
        });
    }
    render() {
        return (
            <div>
                <Navbar showHideNav='hide' urlactivo='business' />
                <main className="content">
                    <div className="wrapper">
                        <section id="Home" className="over_wrapper">
                            <div className="fw_img-extra">
                                <div className="fw_extralight">
                                    <div className="flag-back"></div>
                                    <div className="drop-mn">
                                        <h2>
                                            Confía en nosotros
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="over_wrapper">
                            <div className="MinionActive"></div>
                            <div className="MinionActive"></div>
                            <div className="fw_extralight fw_bs">
                                <h1>¿Estás buscando al mejor talento para tu empresa?</h1>
                            </div>
                            <div className="container-hm">
                                <div className="vd-globe"></div>
                                <div className="vd-globe-line"></div>
                                <div className="custom-container">
                                    <div className="slick-track">
                                        <div className="section-headline">
                                            <h2>Registra tu empresa</h2>
                                            <p>HameServices ha crecido en los últimos años hasta convertirse en una empresa de reclutamiento reconocida a nival nacional, especializándose en industrias específicas del mercado laboral.</p>
                                            <p>Nuestra red se ha extendido a muchos lugares del Perú, debido a que hemos desarrollado un profundo conocimiento que nos permite brindar tanto a nuestros clientes y como a nuestros candidatos, una experiencia invaluable.</p>
                                            <p>Para empezar tu proceso de reclutamiento, compártenos la posición que deseas cubrir a través del siguiente formulario.</p>
                                        </div>
                                    </div>
                                    <div className="ss-kw-rev">
                                        <form className="back-form">
                                            <h1>Recluta con nosotros tu próximo talento</h1>
                                            <p>Si estás en búsqueda de empleo, visita la sección de <Link to="/jobs" className="link_ads">candidatos</Link></p>
                                            <div className="form-one">
                                                <input type="text" placeholder="Nombre" />
                                                <input type="text" placeholder="Apellido" />
                                                <input type="email" placeholder="Email" />
                                                <input type="text" placeholder="Cargo" />
                                                <input type="text" placeholder="Empresa" />
                                                <input type="tel" placeholder="Telefono" />
                                                <select id='cmbAreaSector'>
                                                    {this.state.dataAreaSector.map((areasector) => {
                                                        return (
                                                            <option key={areasector.sequencer} value={areasector.sequencer}>{areasector.nombre}</option>
                                                        )
                                                    })}
                                                </select>
                                                <select id="cmbUbicacion">
                                                    {this.state.dataUbicaciones.map((ubicacion) => {
                                                        return (
                                                            <option key={ubicacion.sequencer} value={ubicacion.sequencer}>{ubicacion.ciudad}</option>
                                                        )
                                                    })}
                                                </select>
                                                <input type="text" placeholder="Descripción del puesto" />
                                            </div>
                                            <button type="submit">ENVIAR</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Piepagina />
                    </div>
                </main>
            </div>
        );
    }
}