const $ = window.$;
var timer;
var timer_load_web=0,timer_second_load_web=0;
$(document).ready(function () {
    $("body").css({ "overflow-y": "hidden" });
    $("body").append("<div id='pre_load_web'><div id='image_load'><img src='/load.gif' width='180px' alt='' /></div><div id='load_ping'></div></div>");
    setInterval(() => {
        if(timer_second_load_web > 4 && timer_load_web == 1){
            $("#pre_load_web").fadeOut("fast", function () {
                $("body").css({ "overflow": "auto" });
            });
            timer_load_web=0;
        }
        timer_second_load_web+=1;
    }, 1000);    
});
$(window).load(function(){
    //Start
    timer_load_web=1;
    $(".nav_collapse ul.nav >li:not(.Active)").mouseenter(function () {
        $(this).addClass("SelectActive");
    }).mouseleave(function () {
        $(this).removeClass("SelectActive")
    })

    $(".nav_collapse:not(.nav-empty) ul.nav >li:nth-child(1)").addClass("Active");

    //Mejorar
    if ($(window).scrollTop() > ($(window).height() - $(".nav_collapse").height() - 250)) {
        $(".navbar").stop(true).animate({ "background": "#f8f8f812" });
        $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "15px" });
        $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "5px" });
        $(".logo_img").stop(true).animate({ "padding": "5px 0px 1px 0px" });
        $(".logo_img img").stop(true).animate({ width: 100 });
        $(".nav-empty").stop(true).animate({ "padding": "0px","margin-top":"5px" }, 100);
    } else {
        $(".navbar").stop(true).animate({ "background": "#000000a3" });
        $(".logo_img img").stop(true).animate({ width: 140 });
        $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "65px" });
        $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "20px" });
        $(".logo_img").stop(true).animate({ "padding": "23px 0px" });
        $(".nav-empty").stop(true).animate({ "padding": "23px 0px","margin-top":"0px" }, 100);
    }
    //Fin Mejora
    $(".wrapper .over_wrapper").each(function (key, element) {
        if ($(window).scrollTop() >= ($(this).offset().top - $(".navbar").height())) {
            $(".nav_collapse:not(.nav-empty) ul.nav >li").removeClass("Active");
            $(".nav_collapse:not(.nav-empty) ul.nav >li >a[href=#" + $(this).attr("id") + "]").parents("li").addClass("Active");
        }
    })

    //$(".nav_collapse ul.nav >li").click(function () {
    //    //$(".nav_collapse ul.nav >li").removeClass("Active");
    //    //$(this).addClass("Active");
    //})

    $(".element_cc >a").mouseenter(function () {
        $(this).parents(".element_cc").find("span").css("opacity", "0.7");
    }).mouseleave(function () {
        $(this).parents(".element_cc").find("span").css("opacity", "0.5");
    })


    $(".item_co,.nav-link,.ico_set,.btn-ref").on("click", function () {
        var n = $(this).find("a").attr("href");
        var posicion = $(n).offset().top;
        $("html, body").animate({
            scrollTop: posicion
        }, "slow");
        //animation Menu
        //$(".nav_collapse ul.nav >li").removeClass("Active");
        if (posicion == $("html, body").scrollTop()) {
            $(".nav_collapse:not(.nav-empty) ul.nav >li >a[href='" + n + "']").parents("li").addClass("Active");
        }
        if (n == "#contact") { $("#txtName").focus(); }
        $(".blur-op").fadeOut();
    });

    var scrollOld = "";
    var Scrollref = "";
    $(window).scroll(function () {
        $(".blur-op").fadeOut();
        //Mejorar
        if ($(window).scrollTop() > ($(window).height() - $(".nav_collapse").height()-250)) {
            $(".navbar").css({ "background": "#000000a3" });
            $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "15px" }, 10);
            $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "5px" }, 10);
            $(".logo_img").stop(true).animate({ "padding": "5px 0px 1px 0px" }, 10);
            $(".logo_img img").stop(true).animate({ width: 100 }, 100);
            $(".mode-visual").stop(true).animate({ margin: "8px 15px" }, 100);
            $(".nav-empty").stop(true).animate({ "padding": "0px","margin-top":"5px" }, 100);
        } else {
            $(".navbar").stop(true).animate({ "background": "#60606012" });
            $(".logo_img img").stop(true).animate({ width: 140 }, 100);
            $(".nav_collapse ul.nav > li:not(.link-adi)").stop(true).animate({ "line-height": "65px" }, 100);
            $(".nav_collapse ul.nav > li.link-adi").stop(true).animate({ "line-height": "20px" }, 100);
            $(".logo_img").stop(true).animate({ "padding": "23px 0px" }, 100);
            $(".mode-visual").stop(true).animate({ margin: "35px 15px" }, 100);
            $(".nav-empty").stop(true).animate({ "padding": "23px 0px","margin-top":"0px" }, 100);
        }
        //Fin Mejorar
        $(".wrapper .over_wrapper").each(function (key, element) {
            if ($(window).scrollTop() >= ($(this).offset().top - $(".navbar").height())) {
                Scrollref = $(".nav_collapse ul.nav >li.Active >a").attr("href");
                $(".nav_collapse:not(.nav-empty) ul.nav >li").removeClass("Active");
                if ($(this).attr("id") != Scrollref) {
                    $(".nav_collapse").removeClass("nav-show").addClass("nav-hide");
                    if ($(".nav_collapse ul.nav >li >a[href=#" + $(this).attr("id") + "]").html() == undefined) {
                        $(".nav_collapse:not(.nav-empty) ul.nav >li >a[href=#" + scrollOld + "]").parents("li").addClass("Active");
                    } else {
                        $(".nav_collapse:not(.nav-empty) ul.nav >li >a[href=#" + $(this).attr("id") + "]").parents("li").addClass("Active");
                    }
                    scrollOld = $(this).attr("id");
                }
            }
        })       
    })

    $(".scroll-link").click(function (e) {
        e.preventDefault();
    })

    $("body").on("mouseenter",".fw_extra_combo .fwsk",function(){
        $(".b1-bk").css("z-index","0");
        $(".b1-bk."+$(this).attr("hv")).css("z-index","9");
        $(".b1-bk."+$(this).attr("hv")).fadeIn("slow");
    })

    var ax=1;
    setInterval(function(){
        if(ax==1){
            $(".img-prd").animate({
                width:100
            },800);
            ax=0;
        }else{
            $(".img-prd").animate({
                width:80
            },800);
            ax=1;
        }
    }, 800);
    $("body").off("click",".fa.fa-bars,.blur-op")
    $("body").on("click",".fa.fa-bars,.blur-op",function(){
        if($(".nav-mov.nav_collapse").hasClass("nav-show")){
            $(".nav-mov.nav_collapse").stop(true).animate({ right: "-100%" }, 500,function(){$(".nav-mov.nav_collapse").removeClass("nav-show").addClass("nav-hide")});
            $(".blur-op").fadeOut("slow");
        }else{
            $(".nav-mov.nav_collapse").css("right","-100%");
            $(".nav-mov.nav_collapse").removeClass("nav-hide").addClass("nav-show");
            $(".nav-mov.nav_collapse").stop(true).animate({ right: "0%" }, 500);
            $(".blur-op").fadeIn("slow");
        }
    })
})
