import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoHS from '../images/logo.png';

export default class Navbar extends Component {
    constructor(props) {
        super();
        this.state = ({
            showHideNav:props.showHideNav,
            urlactivo:props.urlactivo
        })
        
      }
    render() {
        const { showHideNav, urlactivo } = this.state;
        return (
            <header>
                <div className="navbar">
                    <div className="navbar-inner">
                        <div className="logo_img"><Link to="/"><img alt='logo' src={LogoHS} loading="lazy" width="140" /></Link></div>
                        <div className={urlactivo=='inicio'?"nav_collapse nav-mov":"nav_collapse nav-mov nav-empty nav-hide"}>
                            <ul className="nav">
                                {showHideNav=='show'?(<li className="nav-link"><a href="#Home" className="scroll-link">INICIO</a></li>):null}
                                {showHideNav=='show'?(<li className="nav-link"><a href="#homefw" className="scroll-link">NOSOTROS</a></li>):null}
                                {showHideNav=='show'?(<li className="nav-link"><a href="#About" className="scroll-link">BENEFICIOS</a></li>):null}
                                <li className={urlactivo=='business'?"nav-link link-adi Active":"nav-link link-adi"}><Link to="/business">EMPRESAS</Link></li>
                                <li className={urlactivo=='jobs'?"nav-link link-adi Active":"nav-link link-adi"}><Link to="/jobs">CANDIDATOS</Link></li>
                            </ul>
                        </div>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        {/* <div style="clear:both"></div> */}
                    </div>
                </div>
            </header>
        );
    }
}