import React, { Component } from 'react';
import Imagelinewg from '../images/linewg.jpg';

export default class Piepagina extends Component {
    render() {
        return (
            <div>
                <section id="add2" className="over_wrapper">
                    <div className="MinionActive"></div>
                    <img src={Imagelinewg} alt="line" />
                </section>
                <section className="ari-footer">
                    <div className="fw_extralight">
                        <div>
                            <h1>HAME SERVICES © 2023</h1>
                            <p>Empresa especializada en Consultoría de Recursos Humanos y Selección de Personal Calificado.</p>
                        </div>
                        <div>
                            <p><a target="_blank" href="mailto:hameservices@matjos.com"><i className="fa fa-envelope-o" aria-hidden="true"></i>hameservices@matjos.com</a></p>
                            <p><a target="_blank" href="https://api.whatsapp.com/send/?phone=919731360&amp;text=Hola! Quiero realizar una consulta!&amp;app_absent=0"><i className="fa fa-whatsapp" aria-hidden="true"></i>919 731 360</a></p>
                            <p>Perú</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}